import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModalRef } from '@airy-ui/modal';

@Component({
  selector: 'yeda-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConfirmComponent implements OnInit {
  constructor(private modalRef: ModalRef) {}

  ngOnInit(): void {}

  btnConfirm(): void {
    this.modalRef.close(true);
  }

  btnCancel(): void {
    this.modalRef.close(false);
  }
}
