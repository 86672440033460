<air-modal-wrapper panelClass="panel-confirm" class="modal-options" airLoadWhen="checkUser">
  <div class="d-flex flex-column">
    <div class="message">
      {{ 'popup.confirm.message_delete_item' | translate }}
    </div>
    <div class="buttons d-flex flex-row justify-content-around align" airMarginTop="l">
      <button air-button fill="outline" color="danger" (click)="btnConfirm()">Confirm</button>
      <button air-button fill="outline" color="primary" (click)="btnCancel()">Cancel</button>
    </div>
  </div>
</air-modal-wrapper>
